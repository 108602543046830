import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import states from "../../assets/states.json";
import PaymentAmount from "./PaymentAmount";
import { analyticsTracker } from "../../services/analytics";

const PaymentStep2 = ({ billing, amountToPay, onComplete, onBack }) => {

  const [details, setDetails] = React.useState(billing);

  React.useEffect(() => {
      setDetails(billing);
    },
    [billing]);
  useEffect(() => {
    analyticsTracker.event("payment-step2");
  }, []);

  const handleNext = (e) => {
    e.preventDefault();
    onComplete(details, details.exist);
  };

  const handleSaveChange = (e) => {
    setDetails({ ...details, exist: e.target.checked });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Container maxWidth='md'>
      <Grid container>
        <Grid item sm={8} xs={12} padding={1}>
          <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
            <Typography variant='h5' my={2}>Billing Contact</Typography>
            <form onSubmit={handleNext} autoComplete='off'>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id='firstName'
                    name='firstName'
                    label='First name'
                    fullWidth
                    onChange={handleInputChange}
                    value={details.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id='lastName'
                    name='lastName'
                    label='Last name'
                    fullWidth
                    onChange={handleInputChange}
                    value={details.lastName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id='address'
                    name='streetAddress'
                    label='Address line'
                    fullWidth
                    onChange={handleInputChange}
                    value={details.streetAddress}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id='city'
                    name='city'
                    label='City'
                    fullWidth
                    onChange={handleInputChange}
                    value={details.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl required fullWidth>
                    <InputLabel>State</InputLabel>
                    <Select
                      name='state'
                      label='State'
                      defaultValue={""}
                      onChange={handleInputChange}
                      value={details.state}
                    >
                      {states.map((option) => (
                        <MenuItem key={option.abbreviation}
                                  value={option.abbreviation}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id='zip'
                    name='zipCode'
                    label='Zip / Postal code'
                    fullWidth
                    inputProps={{ maxLength: 5, pattern: "[0-9]*" }}
                    onChange={handleInputChange}
                    value={details.zipCode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox color='secondary'
                                       name='exist'
                                       checked={details.exist}
                                       onChange={handleSaveChange} />}
                    label='Save this address for next payments'
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color='inherit'
                  onClick={onBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button type='submit'>Next</Button>
              </Box>
            </form>
          </Paper>
        </Grid>
        <Grid item sm={4} xs={12} padding={1}>
          <PaymentAmount amount={amountToPay} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaymentStep2;
