import {
  Button,
  Container,
  Fab,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { IconExclamationMark } from "@tabler/icons";
import { Link } from "react-router-dom";
import { analyticsTracker } from "../../services/analytics";

const ApplyNewLoanStep3DenyInternal = ({ type }) => {

  useEffect(() => {
    analyticsTracker.event("apply-new-loan-denied");
  }, []);

  return (
    <Container maxWidth='sm'>
      <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
        <Stack direction='column' spacing={2} alignItems='center'>
          <>
            <Fab color='warning' size='medium'>
              <IconExclamationMark width={24} />
            </Fab>
            {type === "RECENT_DENIED_APPLICATION" && <>
              <Typography variant='h5'>
                We're Sorry, Your Application Cannot Be Processed Right Now
              </Typography>
              <Typography variant='body1'>
                Thank you for being a valued customer. Unfortunately, we are
                unable to accept a new loan application at this time due to the
                recent denial of your previous application. The denial was
                likely due to an insufficient number of settled payments on your
                current loan.
              </Typography>
              <Typography variant='body1'>You may reapply again once your next
                payment has successfully settled on your account.</Typography>
              <Typography variant='body1'>While we cannot guarantee approval,
                waiting until after your next payment will improve your chances.
                We appreciate your understanding and look forward to assisting
                you in the future.</Typography>
            </>}
            {type === "GENERAL_ERROR" && <>
              <Typography variant='body1'>
                You can not apply for a new loan right now. Please contact our
                support center.
              </Typography>
            </>}
          </>
          <Button component={Link} to='/dashboard' variant='contained'
                  type='button'
                  color='info'>Return Home</Button>
        </Stack>
      </Paper>
    </Container>
  );
};

export default ApplyNewLoanStep3DenyInternal;
