import DashboardPayCard from "./shared/DashboardPayCard";
import { IconPhone } from "@tabler/icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField
} from "@mui/material";
import React from "react";
import apiService from "../../services/apiService";
import { enqueueSnackbar } from "notistack";
import InputMask from "react-input-mask";
import { LoadingButton } from "@mui/lab";
import { analyticsTracker } from "../../services/analytics";


const RequestCallbackBlock = ({ user, loan }) => {
  const [openCallbackDialog, setOpenCallbackDialog] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  if (!user || !loan) {
    return null;
  }
  const showCallbackBlock = !user.good || user.pendingReUp || !loan.isReUpEnabled;
  if (!showCallbackBlock) {
    return null;
  }

  const handleOpenCallbackDialog = () => {
    analyticsTracker.event("request-callback-dialog-open");
    setOpenCallbackDialog(true);
  };
  const handleCloseCallbackDialog = () => {
    setOpenCallbackDialog(false);
  };

  const handleSubmitCallbackDialog = async () => {
    analyticsTracker.event("request-callback-dialog-submit");
    try {
      setIsLoading(true);
      await apiService.requestCallback({ phone: phoneNumber });
      handleCloseCallbackDialog();
      enqueueSnackbar("Your request was successfully submitted.", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Could not submit callback request. Please try again later or contact support.", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DashboardPayCard icon={<IconPhone size={48} />}
                        title='Request a Callback'
                        description="Click this button if you'd like us to call you back"
                        onClick={handleOpenCallbackDialog} />
      <Divider style={{ width: 275, marginTop: 20, marginBottom: 20 }} />
      <Dialog open={openCallbackDialog} onClose={handleCloseCallbackDialog}
              maxWidth='xs' fullWidth={true}>
        <DialogTitle>Call me back</DialogTitle>
        <DialogContent>
          <InputMask
            mask='+1 (999) 999-9999'
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
          >
            {(inputProps) => <TextField
              autoFocus
              fullWidth
              margin='dense'
              id='phoneNumber'
              label='Phone Number'
              type='tel' {...inputProps}
              placeholder='Enter phone number' />}
          </InputMask>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCallbackDialog} color='primary'
                  disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton onClick={handleSubmitCallbackDialog} color='primary'
                         loading={isLoading}>
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RequestCallbackBlock;
