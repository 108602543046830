import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { analyticsTracker } from "../../services/analytics";

const PaymentStep1 = ({ type, loan, onComplete }) => {
  const [amount, setAmount] = React.useState(0.0);
  const [maxAmount, setMaxAmount] = React.useState(0.0);

  React.useEffect(() => {
    switch (type) {
      case "full":
        setAmount(loan.currentLoanBalance.toFixed(2));
        setMaxAmount(loan.currentLoanBalance.toFixed(2));
        break;
      case "past-due":
        setAmount(loan.feesAndCharges.toFixed(2));
        setMaxAmount(loan.feesAndCharges.toFixed(2));
        break;
      default:
        setMaxAmount(loan.currentLoanBalance.toFixed(2));
        break;
    }
  }, [loan, type]);

  useEffect(() => {
    analyticsTracker.event("payment-step1");
  }, []);

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleNext = (e) => {
    e.preventDefault();
    onComplete(amount);
  };

  const handleCancel = () => {
    window.history.back();
  };

  return (
    <Container maxWidth='sm'>
      {type !== "past-due" &&
        <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
          <Stack direction='column'
                 display='flex'
                 spacing={1}
                 sx={{ flexGrow: 1 }}>
            <Typography variant='h5' my={2} pb={1}>Your total balance
              today</Typography>
            {type !== "past-due" && <Stack spacing={2}
                                           display='flex'
                                           direction='row'
                                           justifyContent='space-between'
                                           alignItems='center'
                                           sx={{ flexGrow: 1 }}>
              <Typography variant='h7'>Principal outstanding:</Typography>
              <Typography variant='h7'>${loan.principalOutstanding}</Typography>
            </Stack>}
            <Stack spacing={2}
                   display='flex'
                   direction='row'
                   justifyContent='space-between'
                   alignItems='center'
                   sx={{ flexGrow: 1 }}>
              <Typography variant='h7'>Current fees and charges:</Typography>
              <Typography variant='h7'>${loan.feesAndCharges}</Typography>
            </Stack>
            <Divider />
            {type === "past-due" && <Stack spacing={2}
                                           display='flex'
                                           direction='row'
                                           justifyContent='space-between'
                                           alignItems='center'
                                           sx={{ flexGrow: 1 }}>
              <Typography variant='h7'>Total past due amount:</Typography>
              <Typography variant='h7'>${amount}</Typography>
            </Stack>}
            {type !== "past-due" && <Stack spacing={2}
                                           display='flex'
                                           direction='row'
                                           justifyContent='space-between'
                                           alignItems='center'
                                           sx={{ flexGrow: 1 }}>
              <Typography variant='h7'>Total outstanding balance:</Typography>
              <Typography variant='h7'>${loan.currentLoanBalance}</Typography>
            </Stack>}
            {/*{type === "full" && loan.feesAndCharges > 0 && <Typography pt={4}>
              A Payment of {formatPriceNumber(loan.feesAndCharges)} is already
              scheduled and can not be modified.
              Therefore the payoff amount is reduced by this scheduled payment.
              Should that scheduled payment be returned, that amount will be
              outstanding on your account
            </Typography>}*/}
          </Stack>
        </Paper>}
      <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
        <form onSubmit={handleNext}>
          <Stack spacing={2}>
            <Typography variant='h5' my={2} pb={1}>
              {type === "full" && "Pay in full"}
              {type === "stand-alone" && "One-time payment"}
              {type === "past-due" && "Pay past due amount"}
            </Typography>
            {type === "past-due" &&
              <>
                <Typography>
                  Great! Let's get you caught up by paying your past-due amount.
                  Once processed, your account will be current.
                </Typography>
                <Divider />
                <Stack spacing={2}
                       display='flex'
                       direction='row'
                       justifyContent='space-between'
                       alignItems='center'
                       sx={{ flexGrow: 1 }}>
                  <Typography>Your current past-due outstanding amount
                    is:</Typography>
                  <Typography>${(loan.feesAndCharges).toFixed(2)}</Typography>
                </Stack>
              </>
            }
            <FormControl fullWidth>
              <Stack direction='row' spacing={1} alignItems='center'
                     justifyContent='center' py={1}>
                <TextField
                  fullWidth
                  label={type === "past-due" ? "Amount you are paying today" : "Amount"}
                  inputProps={{
                    readOnly: type !== "stand-alone",
                    min: 1.00,
                    max: maxAmount,
                    step: 0.01
                  }}
                  type='number'
                  name='amount-to-pay'
                  placeholder='0.0'
                  value={amount}
                  onChange={handleAmountChange}
                />
              </Stack>
            </FormControl>
            <Typography my={2} pb={1}>
              Your payment will be applied first to any past-due amount with the
              remaining amount applied to your outstanding principal.
            </Typography>
          </Stack>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color='inherit'
              onClick={handleCancel}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button disabled={amount === 0.0}
                    type='submit'>Next</Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default PaymentStep1;
